@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  min-height: 100%;
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

.ql-editor {
  font-family: "IBM Plex Sans", sans-serif;
  color: #1d1d1d;
  font-size: 18px;
  padding: 0 !important;
}

.ql-editor::placeholder {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal !important;
}

.ql-container {
  border: none !important;
}

.ql-size {
  color: #1d1d1d !important;
  width: 93px !important;
}

.ql-picker-label {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #1d1d1d;
  margin-right: 15px !important;
}

.ql-toolbar {
  background-color: #ececec !important;
  border-radius: 14px !important;
  box-sizing: border-box !important;
  border: none !important;
  padding: 10px 15px !important;
}

@media (max-width: 390px) {
  .ql-toolbar {
    width: 358px !important;
    height: 46px !important;
  }
}

.ql-size {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #1d1d1d;
  margin-right: 15px !important;
}
